import Video from "@/components/shared/VideoComp/Video";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";

interface VideoGridData {
  heading: {
    title?: string;
    text?: string;
  };
  subheading: {
    title?: string;
    text?: string;
  };
  content_collection: {
    id: string;
    site_content_collections_id: {
      collection: {
        item: {
          title?: string;
          text?: string;
        }
      }
    }
  }[];
  videos: {
    videos_id: {
      embed_url?: string;
      embed_title?: string;
    }
  }[];
  buttons: {
    menu_items_id: {
      label?: string;
      href?: string;
    }
  }[];
}

export const fields = [
  "heading.title",
  "heading.text",
  "subheading.title", 
  "subheading.text",
  "content_collection.id",
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",
  "videos.videos_id.embed_url",
  "videos.videos_id.embed_title",
  "buttons.menu_items_id.label",
  "buttons.menu_items_id.href"
] as const;

interface VideoGridProps {
  data: VideoGridData;
}

export default function VideoGrid({ data }: VideoGridProps) {

  return (
    <section id="video-grid" className="py-[5.25rem] px-4 md:px-8 lg:px-16">
      <div className="max-w-[1080px] mx-auto">
        <div className="text-center mb-12 px-4 md:px-8">
          <h4 
            className="font-aileron font-bold text-xs uppercase tracking-[0.125rem] mb-6 text-black"
            dangerouslySetInnerHTML={sanitizeHTML(data?.subheading?.title || data?.subheading?.text || '')}
          />
          <h2 
            className="font-freightBig text-4xl md:text-5xl text-[#332f2c] leading-tight px-4"
            dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || data?.heading?.text || '')}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 mb-8">
          {data?.videos?.map((video, index) => (
            <div key={index} className="relative w-full" style={{ paddingBottom: '56.25%' }}>
              <iframe
                src={video?.videos_id?.embed_url}
                title={video?.videos_id?.embed_title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
                loading={"lazy"}
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {data?.buttons?.map((button, index) => (
            <div key={index} className="flex justify-center">
              <Button variant="outline" asChild>
                <Link href={button?.menu_items_id?.href || ''}>
                  {button?.menu_items_id?.label}
                </Link>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
