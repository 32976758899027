import Video from "@/components/shared/VideoComp/Video";
import { sanitizeHTML } from "@/lib/utils";

interface VideosVFR7ResultsData {
  heading: {
    text: string;
    title: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  text: {
    text: string;
  };
  videos: {
    videos_id: {
      embed_url: string;
      embed_title: string;
      thumbnail: {
        id: string;
        filename_download: string;
      };
    };
  }[];
}

interface VideosVFR7ResultsProps {
  data: VideosVFR7ResultsData;
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text",
  "subheading.title",
  "text.text",
  "videos.videos_id.embed_url",
  "videos.videos_id.embed_title",
  "videos.videos_id.thumbnail.id",
  "videos.videos_id.thumbnail.filename_disk",
] as const;

export default function VideosVFR7Results({ data }: VideosVFR7ResultsProps) {
  return (
    <section
      id="videos-vfr7-results"
      className="bg-[rgba(177,163,164,0.15)] py-12 md:py-16"
    >
      <div className="max-w-[1080px] mx-auto px-4 md:px-8">
        <div className="max-w-4xl mx-auto text-center mb-12">
          <h4 className="font-aileron font-bold uppercase text-sm tracking-[2px] text-black mb-4">
            {data?.subheading?.text || data?.subheading?.title || ''}
          </h4>
          <h2 className="font-freightBig text-3xl md:text-4xl lg:text-[44px] text-black leading-tight mb-4">
            {data?.heading?.title || ''}
          </h2>
          <div
            className="font-aileron text-base text-black  prose-p:m-0 w-full"
            dangerouslySetInnerHTML={ sanitizeHTML(data?.text?.text || '')}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[5.5%]">
          {data?.videos?.map((video, i) => (
            <iframe
              loading={"lazy"}
              title={video?.videos_id?.embed_title}
              src={video?.videos_id?.embed_url}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              name={`fitvid${i + 3}`}
              className="size-full"
              key={i}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
