"use client";

import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLoadingState } from '@/hooks/useLoadingState';
import { usePathname, useSearchParams } from 'next/navigation';

export default function LoadingBar() {
  const { isLoading, setIsLoading } = useLoadingState();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setIsLoading(false);
  }, [pathname, searchParams, setIsLoading]);

  return (
    <>
      {isLoading && (
        <motion.div
          className="fixed top-0 left-0 right-0 z-[9999] h-0.5 bg-black origin-left"
        initial={{ scaleX: 0 }}
        animate={isLoading ? { scaleX: 0.99, opacity: 1 } : { scaleX: 0, opacity: 0 }}
        exit={{ scaleX: 1 }}
        transition={{ duration: 0.9 }}
      />
      )}
    </>
  );
} 