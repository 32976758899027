import { useState } from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";

interface VideoProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  imgSrc: string;
}

const Video = ({ children, className, imgSrc, ...props }: VideoProps) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <div
      className={cn(
        "relative w-full h-auto aspect-[640/360] overflow-hidden",
        className
      )}
      {...props}
    >
      {showVideo ? (
        children
      ) : (
        <div
          className="w-full h-full cursor-pointer group relative"
          onClick={() => setShowVideo(true)}
        >
          <Image
            src={imgSrc}
            alt="Video Thumbnail"
            width={640}
            height={360}
            className="object-cover w-full h-full"
            quality={95}
            loading={"lazy"}
          />
          <div className="absolute inset-0 bg-black/40 transition-opacity duration-300 flex items-center justify-center">
            <button className="w-16 h-16 md:w-24 md:h-24 bg-white rounded-full flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 md:w-8 md:h-8 ml-1"
              >
                <path d="M6 4L18 12L6 20V4Z" fill="black" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
