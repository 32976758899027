import Image from "next/image";
import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

interface SplitItem {
  title: string;
  imageSrc: string;
}

export type SplitItems = [SplitItem, SplitItem];

interface SplitImagesWithTitleProps extends HTMLAttributes<HTMLDivElement> {
  items: SplitItems;
}

export default function SplitImagesWithTitle({
  items,
  className,
  ...props
}: SplitImagesWithTitleProps) {
  return (
    <div
      className={cn("grid grid-cols-1 md:grid-cols-2 gap-8", className)}
      {...props}
    >
      {items.map((item, index) => (
        <div key={index} className="space-y-4">
          <h4 className="font-freightBig text-2xl text-secondary-dark relative pl-16 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-10 before:h-px before:bg-gray-300">
            {item.title}
          </h4>
          <div className="relative aspect-[4/3] w-full overflow-hidden rounded-lg">
            <Image
              src={item.imageSrc}
              alt={item.title}
              fill
              className="object-cover"
              quality={95}
              loading={"lazy"}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
