import Image from "next/image";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface WhatWeOfferData {
  subheading?: {
    text?: string;
    title?: string;
  };
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  content_collection?: {
    id: string;
    site_content_collections_id: {
      collection: {
        collection: string;
        item: {
          //site_content
          title: string;
          text: string;
          
          //menu_items
          href: string;
          label: string;
            
          //images
          alt: string;
          image_file: {
            image: {
              id: string;
              filename_download: string;
            };
          };
            
        };
      }[];
    };
  }[];
}

export const fields = [
  "subheading.text",
  "subheading.title",

  "heading.text",
  "heading.title",

  "text.text",

  "content_collection.id",
  "content_collection.site_content_collections_id.collection.collection",
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",
  
  "content_collection.site_content_collections_id.collection.item:images.alt",
  "content_collection.site_content_collections_id.collection.item:images.image_file.image.id",
  "content_collection.site_content_collections_id.collection.item:images.image_file.image.filename_download",

  "content_collection.site_content_collections_id.collection.item:menu_items.href",
  "content_collection.site_content_collections_id.collection.item:menu_items.label",


] as const;

interface WhatWeOfferProps {
  data?: WhatWeOfferData;
}

export default function WhatWeOffer({ data }: WhatWeOfferProps) {
  return (
    <section className="py-24 bg-white">
      <div className="container">
        <div className="text-center mb-16">
          <span className="text-sm font-bold">{data?.subheading?.title || ""}</span>
          <h2 className="text-4xl font-freightBig mb-6">
            {data?.heading?.title || ""}
          </h2>
          <div 
            className="text-secondary-dark max-w-4xl mx-auto prose"
            dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {data?.content_collection?.map((collection, index) => {
            const imageItem = collection?.site_content_collections_id?.collection.find(item => item.collection === "images")?.item;
            const titleItem = collection?.site_content_collections_id?.collection.find(item => item.collection === "site_content" && item.item.title)?.item;
            const textItem = collection?.site_content_collections_id?.collection.find(item => item.collection === "site_content" && item.item.text)?.item;
            const menuItem = collection?.site_content_collections_id?.collection.find(item => item.collection === "menu_items")?.item;

            const imageId = imageItem?.image_file?.image?.id || "";
            const imageFilename = imageItem?.image_file?.image?.filename_download || "";
            const imageAlt = imageItem?.alt || "";
            const title = titleItem?.title || "";
            const text = textItem?.text || "";
            const menuItems = menuItem;

            return (
              <div key={index} className="space-y-6">
                <div className="aspect-w-4 aspect-h-3 relative">
                  <Image
                    src={getReadableImageUrl(imageId, imageFilename)}
                    alt={imageAlt}
                    fill
                    className="object-cover"
                    quality={95}
                    loading={"lazy"}
                  />
                </div>
                <div>
                  <h3 className="text-3xl font-freightBig mb-4">
                    {title}
                  </h3>
                  <div 
                    className="text-secondary-dark mb-6 prose"
                    dangerouslySetInnerHTML={sanitizeHTML(text)}
                  />
                  <div className="text-center">
                    <Button variant="outline" asChild>
                      <Link href={menuItems?.href || "#"}>
                        {menuItems?.label || ""}
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
