import Image from "next/image";
import Button from "../ui/button";
import Link from "@/components/ui/Link";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface WelcomeToPracticeData {
  subheading?: {
    text?: string;
    title?: string;
  };
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
  image?: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

export const fields = [
  "subheading.text",
  "heading.text", 
  "subheading.title",
  "heading.title",
  "text.text",
  "button.label",
  "button.href",
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download"
] as const;

interface WelcomeToPracticeProps {
  data: WelcomeToPracticeData;
}

export default function WelcomeToPractice({ data }: WelcomeToPracticeProps) {
  const imageUrl = getReadableImageUrl(data?.image?.image_file?.image?.id || "", data?.image?.image_file?.image?.filename_download || "");
  
  return (
    <section id="WelcomeToPractice" className="py-12">
      <div className="container flex flex-col lg:flex-row gap-12 items-start justify-between">
        <div className="prose max-w-none">
          <span className="font-bold text-sm">{data?.subheading?.title || ""}</span>
          <h2 className="text-2xl md:text-4xl mb-6 mt-2 font-light md:mb-12 font-freightBig">
            {data?.heading?.title || ""}
          </h2>
          <div 
            className="mb-10 md:mb-20 prose prose-p:mb-0"
            dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
          />
          {data?.button && (
            <Button asChild>
              <Link href={data?.button?.href || ""}>{data?.button?.label || ""}</Link>
            </Button>
          )}
        </div>
        <Image
          src={imageUrl}
          alt={data?.image?.alt || ""}
          className="object-cover lg:w-1/2"
          width={900}
          height={600}
          quality={95}
          loading={"lazy"}
        />
      </div>
    </section>
  );
}
