"use client";

import NextLink from 'next/link';
import { useLoadingState } from '@/hooks/useLoadingState';
import type { LinkProps as NextLinkProps } from 'next/link';

interface ClientLinkProps extends NextLinkProps {
  children: React.ReactNode;
  className?: string;
  target?: string;
  onClick?: () => void;
  prefetch?: boolean;
}

export default function Link({ children, onClick, target, prefetch, ...props }: ClientLinkProps) {
  const { setIsLoading } = useLoadingState();
  

  const handleClick = () => {
    setIsLoading(true);
    if (onClick) onClick();
    
  };

  return (
    <NextLink {...props} onClick={handleClick}
     prefetch={prefetch}>
      {children}
    </NextLink>
  );
} 