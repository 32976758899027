import Image from "next/image";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { getReadableImageUrl } from "@/lib/directus";

interface Images {
  images_id: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

const BACarousel = ({ images = [] }: { images?: Images[] }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="p-1">
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        plugins={[
          Autoplay({
            delay: 5000,
          }),
        ]}
        className="w-full max-w-[1200px] mx-auto"
      >
        <CarouselContent className="-ml-2 md:-ml-4">
          {images.map((image, index) => (
            <CarouselItem
              key={index}
              className={`pl-2 md:pl-4 ${
                isMobile
                  ? "basis-full"
                  : images.length > 2
                  ? "basis-1/3"
                  : "basis-1/2"
              }`}
            >
              <div className="relative aspect-[3/2]">
                <Image
                  src={getReadableImageUrl(
                    image.images_id.image_file.image.id,
                    image.images_id.image_file.image.filename_download
                  )}
                  alt={image.images_id.alt}
                  fill
                  quality={95}
                  className="rounded-lg object-cover"
                  loading={"lazy"}
                />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="hidden md:flex" />
        <CarouselNext className="hidden md:flex" />
      </Carousel>
    </div>
  );
};

export default BACarousel;
